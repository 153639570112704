<template>
  <div>
    <div
      class="
        subheader
        py-5 py-lg-10
        gutter-b
        subheader-transparent subheader-background
        bg-dark
        rounded
      "
      id="kt_subheader"
      style="
        background-image: url('media/svg/patterns/taieri.svg');
        background-position: 100% 100%;
        background-size: auto 100%;
        background-repeat: no-repeat;
      "
    >
      <div class="d-flex flex-column container-fluid">
        <!--begin::Title-->
        <div class="d-flex align-items-sm-end flex-column flex-sm-row mb-5">
          <h2 class="text-white mr-5 mb-0">Selamat Datang</h2>
          <span class="text-white opacity-60 font-weight-bold"
            >Sistem Informasi Ujian Online Terintegrasi</span
          >
        </div>
        <!--end::Title-->
        <!--begin::Search Bar-->
        <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row">
          <div class="bg-white rounded p-4 d-flex flex-grow-1 flex-sm-grow-0">
            <!--begin::Form-->
            <form
              class="
                form
                d-flex
                align-items-md-center
                flex-sm-row flex-column flex-grow-1 flex-sm-grow-0
              "
            >
              <!--begin::Input-->
              <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                <div class="symbol symbol-50 symbol-light-info mr-4">
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-2x svg-icon-info">
                      <inline-svg
                        src="media/svg/icons/Files/File.svg"
                        class="h-75"
                      />
                    </span>
                  </span>
                </div>
                <b-skeleton width="100px" v-if="isLoading"></b-skeleton>
                <div
                  v-else-if="
                    isSiswa
                      ? currentMyUjian.data.length > 0
                      : currentUjianList.data.length > 0
                  "
                  class="mr-4"
                >
                  <div class="font-size-sm text-muted font-weight-bold">
                    Total Ujian
                  </div>
                  <div class="font-size-h4 font-weight-bolder">
                    {{
                      isSiswa
                        ? currentMyUjian.data.length
                        : currentUjianList.data.length
                    }}
                  </div>
                </div>
              </div>
              <!--end::Input-->
              <!--begin::Input-->
              <span
                class="bullet bullet-ver h-50px d-none d-sm-flex mr-2"
              ></span>
              <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                <div class="symbol symbol-50 symbol-light-primary mr-4">
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-2x svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Files/File-done.svg"
                        class="h-75"
                      />
                    </span>
                  </span>
                </div>
                <b-skeleton width="100px" v-if="isLoading"></b-skeleton>
                <div
                  v-else-if="authUser.user && currentUjianList.data.length > 0"
                  class="mr-4"
                >
                  <div class="font-size-sm text-muted font-weight-bold">
                    {{ isSiswa ? "Ujian Dikerjakan" : "Ujian Aktif" }}
                  </div>
                  <div class="font-size-h4 font-weight-bolder">
                    <!-- {{
                      isSiswa
                        ? currentUjianList.filter(
                            (item) => item.one_nilai_ujian.nilai
                          ).length
                        : currentUjianList.data.filter(
                            (item) => item.is_published
                          ).length
                    }} -->
                  </div>
                </div>
              </div>
              <!--end::Input-->
              <!--begin::Input-->
              <span
                class="bullet bullet-ver h-50px d-none d-sm-flex mr-2"
              ></span>
              <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                <div class="symbol symbol-50 symbol-light-warning mr-4">
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-2x svg-icon-warning">
                      <inline-svg
                        src="media/svg/icons/Files/Deleted-file.svg"
                        class="h-75"
                      />
                    </span>
                  </span>
                </div>
                <b-skeleton width="100px" v-if="isLoading"></b-skeleton>
                <div
                  v-else-if="
                    authUser.user &&
                    (isSiswa
                      ? currentMyUjian.data.length > 0
                      : currentUjianList.data.length > 0)
                  "
                  class="mr-4"
                >
                  <div class="font-size-sm text-muted font-weight-bold">
                    {{
                      isSiswa ? "Ujian Belum Dikerjakan" : "Ujian Tidak Aktif"
                    }}
                  </div>
                  <div class="font-size-h4 font-weight-bolder">
                    <!-- {{
                      isSiswa
                        ? currentUjianList.filter(
                            (item) => !item.one_nilai_ujian.nilai
                          ).length
                        : currentUjianList.data.filter(
                            (item) => !item.is_published
                          ).length
                    }} -->
                  </div>
                </div>
              </div>
              <!--end::Input-->
              <router-link
                to="/ujian"
                class="
                  btn btn-dark
                  font-weight-bold
                  btn-hover-light-primary
                  mt-3 mt-sm-0
                  px-7
                "
              >
                Lihat semua
              </router-link>
            </form>
            <!--end::Form-->
          </div>
          <!--begin::Advanced Search-->
          <!--          <div class="mt-4 my-md-0 mx-md-10">-->
          <!--            <a href="#" class="text-white font-weight-bolder text-hover-primary"-->
          <!--            >Advanced Search</a-->
          <!--            >-->
          <!--          </div>-->
          <!--end::Advanced Search-->
        </div>
        <!--end::Search Bar-->
      </div>
    </div>
    <b-row class="justify-content-between align-items-end">
      <b-col cols="auto">
        <h4 class="text-dark-75 font-weight-bolder text-hover-primary mb-1">
          Overview Ujian
        </h4>
      </b-col>
      <b-col cols="auto">
        <router-link
          to="/ujian"
          class="
            text-dark-75
            font-weight-bolder
            text-hover-primary
            mb-1
            font-size-lg
            d-flex
            flex-row
            align-items-center
          "
        >
          Lihat semua
          <i class="mdi mdi-chevron-right"></i>
        </router-link>
      </b-col>
    </b-row>
    <div class="row mt-4" v-if="isLoading">
      <div class="col-lg-4 col-md-6" v-for="i in 3" :key="i">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Body-->
          <div class="card-body d-flex flex-column">
            <div class="flex-grow-1 pb-5">
              <!--begin::Info-->
              <div class="d-flex align-items-center pr-2 mb-6">
                <span
                  class="text-muted font-weight-bold font-size-lg flex-grow-1"
                >
                  <b-skeleton width="100px"></b-skeleton>
                </span>
                <div class="symbol symbol-50">
                  <span class="symbol-label bg-light-light">
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg
                        src="media/svg/icons/Files/File.svg"
                        class="h-75"
                      />
                    </span>
                  </span>
                </div>
              </div>
              <!--end::Info-->
              <!--begin::Link-->
              <a
                href="#"
                class="
                  text-dark
                  font-weight-bolder
                  text-hover-primary
                  font-size-h4
                "
              >
                <b-skeleton width="200px"></b-skeleton>
                <b-skeleton width="100px"></b-skeleton>
              </a>
              <!--end::Link-->
              <!--begin::Desc-->
              <p class="text-dark-50 font-weight-normal font-size-lg mt-6">
                <b-skeleton width="250px"></b-skeleton>
                <b-skeleton width="150px"></b-skeleton>
                <b-skeleton width="50px"></b-skeleton>
              </p>
              <!--end::Desc-->
            </div>
            <!--begin::Team-->
            <!--            <div class="d-flex align-items-center">-->
            <!--              &lt;!&ndash;begin::Pic&ndash;&gt;-->
            <!--              <a href="#" class="symbol symbol-45 symbol-light mr-3">-->
            <!--                <div class="symbol-label">-->
            <!--                  <img-->
            <!--                      src="media/svg/avatars/001-boy.svg"-->
            <!--                      class="h-75 align-self-end"-->
            <!--                      alt=""-->
            <!--                  />-->
            <!--                </div>-->
            <!--              </a>-->
            <!--              &lt;!&ndash;end::Pic&ndash;&gt;-->
            <!--            </div>-->
            <!--end::Team-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div
      class="card card-custom card-stretch gutter-b mt-4"
      v-else-if="
        !isLoading &&
        (isSiswa
          ? currentMyUjian.data.length
          : currentUjianList.data.length == 0)
      "
    >
      <!--begin::Body-->
      <div class="card-body d-flex flex-column">
        <h6 class="text-center mb-0">Tidak ada ujian ditemukan</h6>
      </div>
    </div>
    <div class="row mt-4" v-else>
      <div
        class="col-lg-4 col-md-6"
        v-for="ujian in currentUjianList.data"
        :key="`ujian${ujian.id}`"
      >
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Body-->
          <div class="card-body d-flex flex-column">
            <div class="flex-grow-1 pb-5">
              <!--begin::Info-->
              <div class="d-flex align-items-center pr-2 mb-6">
                <span
                  v-if="isSiswa"
                  class="text-muted font-weight-bold font-size-lg flex-grow-1"
                  >{{ ujian.created_at | moment("from") }}</span
                >
                <span
                  v-else
                  :class="`${
                    ujian.published ? 'text-primary' : 'text-muted'
                  } font-weight-bold font-size-lg flex-grow-1`"
                  >{{ ujian.published ? "Aktif" : "Tidak Aktif" }}</span
                >
                <!--              <span class="text-muted font-weight-bold font-size-lg flex-grow-1">Updated at {{ ujian.updated_at | moment("LL HH:mm:ss") }}</span>-->
                <div class="symbol symbol-50">
                  <span class="symbol-label bg-light-light">
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg
                        src="media/svg/icons/Files/File.svg"
                        class="h-75"
                      />
                    </span>
                  </span>
                </div>
              </div>
              <!--end::Info-->
              <!--begin::Link-->
              <router-link
                v-if="!isSiswa"
                :to="`ujian/${ujian._id}/soal`"
                class="
                  text-dark
                  font-weight-bolder
                  text-hover-primary
                  font-size-h4
                "
                >{{ ujian.name }}</router-link
              >
              <div
                v-else
                class="
                  text-dark
                  font-weight-bolder
                  text-hover-primary
                  font-size-h4
                "
              >
                {{ ujian.nama }}
              </div>
              <span class="text-muted font-weight-bold d-block">
                <!-- {{ ujian.kurikulum.mapel.nama_mapel }} -->
              </span>
              <span
                v-if="isSiswa"
                class="
                  label label-lg label-inline
                  font-weight-bold
                  py-4
                  label-light-info
                  mr-1
                  mt-1
                "
              >
                {{
                  ujian.one_nilai_ujian.nilai != null
                    ? "Sudah dikerjakan"
                    : currentAttempt.id == ujian.id
                    ? "Sedang dikerjakan"
                    : "Belum dikerjakan"
                }}
              </span>
              <!--end::Link-->
              <!--begin::Desc-->
              <p
                class="text-dark-50 font-weight-normal font-size-lg mt-6"
                v-html="ujian.deskripsi"
              ></p>
              <!--end::Desc-->
              <span
                class="
                  label label-lg label-inline
                  font-weight-bold
                  py-4
                  label-light-success
                  mr-1
                "
              >
                {{ ujian.opening_time | moment("LL HH:mm") }}
              </span>
              -
              <span
                class="
                  label label-lg label-inline
                  font-weight-bold
                  py-4
                  label-light-danger
                  ml-1
                "
              >
                {{ ujian.closing_time | moment("LL HH:mm") }}
              </span>
            </div>
            <!--begin::Team-->
            <div class="d-flex align-items-center" v-if="isSiswa">
              <!--begin::Pic-->
              <a href="#" class="symbol symbol-45 symbol-light mr-3">
                <img
                  class="symbol-label"
                  :src="`https://eschool.smkn4bdg.sch.id/img.php?src=http://img.smkn4bdg.sch.id/guru/${ujian.guru.grnrs}.jpg`"
                  alt=""
                />
              </a>
              <div class="mr-4">
                <div class="font-size-sm text-muted font-weight-bold">Oleh</div>
                <div class="font-size-sm font-weight-bolder">
                  {{ ujian.guru.bio.nama_lengkap }}
                </div>
              </div>
              <!--end::Pic-->
            </div>
            <!--end::Team-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { GET_UJIAN, GET_MY_UJIAN } from "@/core/services/store/ujian.module";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
// import KTLayoutAside from "@/assets/js/layout/base/aside";
// import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu";
export default {
  name: "Dashboard",
  data() {
    return {
      isLoading: false,
    };
  },
  beforeMount() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);

    if (this.isSiswa || this.isTeacher) {
      this.getData();
    }
    this.$nextTick(() => {
      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters([
      "currentUjianList",
      "authUser",
      "currentAttempt",
      "currentMyUjian",
    ]),
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return this.currentUser.role == "student";
    },
    isTeacher() {
      return this.currentUser.role == "teacher";
    },
  },
  methods: {
    getData() {
      const params = new URLSearchParams();
      params.append("limit", 50);
      params.append("page", 1);
      params.append("is_already_done",0);

      this.isLoading = true;
      this.$store
        .dispatch(this.isSiswa ? GET_MY_UJIAN : GET_UJIAN, params.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.isSiswa
            ? this.currentMyUjian.total
            : this.currentUjianList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
